import { H1 } from '../../components/atoms/Typography';
import { PaperCollapse } from '../../components/molecules/PaperCollapse';
import { Faq, Faqs } from '../../components/atoms/Faq';
import { Spacer } from '../../components/atoms/Spacer/Spacer';
import { CopyColoredSpan } from '../../components/atoms/Typography/Copy';

const FaqsElement = () => {
  return (
    <>
      <H1>FAQs</H1>

      <PaperCollapse title="Getting started?" open={true}>
        <Faqs>
          <Faq title="How can I start with JobsBoard.io?">
            Starting your job search journey with jobsboard.io is both simple and completely free! Just sign up for our
            Free plan, and you can immediately enhance your job hunting experience. Enjoy the freedom to use
            jobsboard.io for as long as you need; there's no trial period or time limit to worry about.
          </Faq>
          <Faq title="How can I access the plans?">
            To access our plans, visit our website and create a jobsBoard.io account. Once registered, navigate to your
            settings where you can easily choose between our Free and Premium plans. It's a seamless process that allows
            you to upgrade to a Premium plan or continue enjoying the features of the Free plan.
          </Faq>
          <Faq title="Can I switch between plans?">
            Yes, you have the flexibility to switch between our Free and Premium plans at any time. If you choose to
            downgrade, you'll continue to enjoy the Premium plan benefits until your next invoice date, ensuring a
            seamless transition between plans.
          </Faq>
          <Faq title="Is there a time limit to use JobsBoard.io?">
            There is no time limit for using JobsBoard.io. You can use the platform indefinitely with basic
            customization options without any charge. The app offers advanced customization features which come with a
            cost, clearly labeled within the app interface. You have the freedom to organize your job search effectively
            and choose to pay only for the additional, enhanced functionalities you need
          </Faq>
        </Faqs>
      </PaperCollapse>

      <Spacer y={62} />

      <PaperCollapse title="Free Plan" open={true}>
        <Faqs>
          <Faq title="Can I get a free trial?">
            Absolutely! JobsBoard.io offers a free version for users who do not opt for paid customization features.
            Enjoy the basic functionalities without any cost!
          </Faq>
          <Faq title="Is the AI Assistant feature available in the Free plan?">
            No, the AI Assistant feature is exclusively offered in the Premium Plan. It aids in accelerating copywriting
            by offering suggestions and provides valuable assistance in your job search.
          </Faq>
          <Faq title="Can I upgrade from the Free plan?">
            Certainly! If you require extra features and capabilities, upgrading to our Premium plan will unlock a host
            of additional functionalities for you.
          </Faq>
          <Faq title="Is there any limit on the number of Job Applications I can manage?">
            Yes, there are some limitations with the Free plan. However, with the Premium plan, there are no limits! You
            have the freedom to manage as many Boards and Job Applications as you need. Just remember, for advanced
            customization options, upgrading to a paid subscription plan is necessary. Enjoy the flexibility of managing
            your Jobs Board and applications without any restrictions!
          </Faq>
          <Faq title="What payment methods do you accept?">
            We accept payments through various convenient methods, including all major credit cards, Apple Pay, Google
            Pay, and more. Rest assured, your transactions are processed securely through Stripe, a trusted payment
            provider utilized by reputable companies such as Google, Amazon, and Booking.com. Your financial security is
            our priority.
          </Faq>{' '}
          <Faq title="Do I need to commit to a long-term contract?">
            Nope! There are no enterprise lock-ins of any kind. You have the flexibility to choose between a monthly
            payment option or commit to a quarterly plan at a discounted rate. We believe in giving you the freedom to
            choose the plan that best suits your needs.
          </Faq>
        </Faqs>
      </PaperCollapse>

      <Spacer y={62} />

      <PaperCollapse title="Premium Plan" open={true}>
        <Faqs>
          <Faq title="Is there a trial period available for the Premium plan?">
            No but we offer a hassle-free 14-day refund policy. If you're not satisfied, you can receive a full refund
            within this period. Simply contact us, and we'll take care of the rest
          </Faq>
          <Faq title="What’s your refund policy?">
            We offer a 14-day money-back guarantee. If you are not satisfied with our product, we will refund your
            purchase - no questions asked! Simply contact us within 14 days of your purchase, and we will take care of
            the rest. By the way, you have the flexibility to cancel and downgrade at any time and continue using
            JobsBoard.io for free.
          </Faq>
          <Faq title="What’s your cancellation policy?">
            You can cancel your subscription at any time. Once you cancel your subscription, you will not be charged
            further. However, you will not be refunded for the current billing cycle.
          </Faq>
          <Faq title="What’s your pricing?">
            Our pricing varies depending on the specific Premium plan you choose. For a detailed breakdown of our
            Premium plans, please visit our Pricing Page. It's important to note that the job search process typically
            takes from three to six months for most individuals.
          </Faq>
          <Faq title="Does JobsBoard.io AI use GPT-4?">
            Absolutely! Our JobsBoard.io AI is powered by OpenAI's advanced GPT-4 technology, ensuring an intelligent
            and efficient AI assistant. In addition to GPT-4, our platform leverages the capabilities of GPT-3.5 Turbo,
            creating a dynamic synergy for an enhanced user experience.
          </Faq>
          <Faq title="What is JobsBoard.io AI credits policy?">
            JobsBoard.io, along with AI credits, is designed to facilitate fair and efficient utilization of our AI
            services. With each membership, you receive a designated monthly allocation of credits, representing the
            capacity for AI usage. This system empowers your team to make the most of AI benefits while promoting
            resource optimization. It's worth noting that to maintain optimal performance and equitable access for all
            JobsBoard.io users, your access to AI features may be adjusted based on your usage. This ensures a balanced
            and fair experience for everyone on our platform.
          </Faq>
        </Faqs>
      </PaperCollapse>
    </>
  );
};

export default FaqsElement;
